export const photos = [
  {
    src: require("../img/gallery/gallery_02.jpg"),
    width: 5,
    height: 3
  },
  {
    src: require("../img/gallery/gallery_03.jpg"),
    width: 3,
    height: 3
  },
  {
    src: require("../img/gallery/gallery_04.jpg"),
    width: 4,
    height: 3
  },
  {
    src: require("../img/gallery/gallery_06.jpg"),
    width: 8,
    height: 3
  },
  {
    src: require("../img/gallery/gallery_07.jpg"),
    width: 6,
    height: 3
  },
  {
    src: require("../img/gallery/gallery_08.jpg"),
    width: 6,
    height: 3
  },
  {
    src: require("../img/gallery/gallery_09.jpg"),
    width: 4,
    height: 3
  },
  {
    src: require("../img/gallery/gallery_10.jpg"),
    width: 4,
    height: 3
  },
  {
    src: require("../img/gallery/gallery_11.jpg"),
    width: 4,
    height: 3
  },
  {
    src: require("../img/gallery/gallery_12.jpg"),
    width: 3,
    height: 2
  },
  {
    src: require("../img/gallery/gallery_13.jpg"),
    width: 3,
    height: 2
  }
];
