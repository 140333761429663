import React from "react";

const Footer = ({ year, property, link, creator }) => {
  return (
    <>
      <footer>
        ©{year} {property}. All rights reserved. <br />
        Website ported to ReactJS by <a href={link}>{creator}</a>
      </footer>
    </>
  );
};

export default Footer;
