import React from "react";

const Content = ({ background, logo }) => {
  return (
    <>
      <div id="the-resto" style={{ background: `url(${background}) repeat` }}>
        <div className="container">
          <div className="the-resto__logo">
            <img src={logo} alt="Kertanegara" />
          </div>
          <div className="the-resto__text">
            <h1>Rumah Makan Kertanegara</h1>
            <h2>
              Just like how we love delicious details, we also love to serve you
              those delicacies in dishes to be enjoyed alongside our
              restaurant&rsquo;s unique atmosphere.
            </h2>
            <p>
              Located at the heart of Malang, Indonesia, Rumah Makan Kertanegara
              is a restaurant with classic and unique Java nuance. It was
              founded on 2007 with a commitment to spoil the tongue of culinary
              connoisseurs by serving various types of food: Indonesian food,
              Chinese food, seafood, and grilled food.
            </p>
            <p>
              Rumah Makan Kertanegara also provides meeting room with projector
              facility, Wi-Fi area, live music, as well as a large and safe
              parking area. All of those plus accessible location are making
              Kertanegara the right choice to hold various events such as
              wedding, anniversary party, meeting, social gathering, or
              reunions.
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default Content;
