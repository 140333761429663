import React from "react";
import logo from "../img/svg/logo-k.svg";

export class Navigation extends React.Component {
  render() {
    return (
      <>
        <header className="show-desktop">
          <div className="container">
            <div
              className="header__logo nav"
              style={{ background: `url(${logo}) no-repeat 0 0` }}
            >
              Kertanegara
            </div>
            <div className="header__nav--container">
              <ul className="header__nav nav">
                <li>
                  <a href="#the-resto">The Resto</a>
                </li>
                <li>
                  <a href="#daily-serving">Hours</a>
                </li>
                <li>
                  <a href="#menu">Menu</a>
                </li>
                <li>
                  <a href="#gallery">Gallery</a>
                </li>
                <li className="no-border">
                  <a href="#contact">Contact</a>
                </li>
                <li>
                  <a href="#contact">+62-341-366203</a>
                </li>
              </ul>
            </div>
          </div>
        </header>
      </>
    );
  }
}

export default Navigation;
