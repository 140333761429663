import React from "react";
import Gallery from "react-photo-gallery";

const MozaicGallery = ({ photos }) => {
  return (
    <>
      <div
        id="gallery"
        style={{
          background: `url(${require("../img/BG-Web-3.jpg")}) repeat`
        }}
      >
        <div className="container">
          <h1>Gallery</h1>
        </div>
        <Gallery photos={photos} />;
      </div>
    </>
  );
};

export default MozaicGallery;
