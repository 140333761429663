import React from "react";

const SmallBanner = ({ background, logo }) => {
  return (
    <>
      <div
        id="daily-serving"
        style={{ background: `url(${background}) repeat` }}
      >
        <div
          className="daily-serving__inner"
          style={{ background: `url(${background}) repeat` }}
        >
          <div className="container">
            <div className="daily-serving__head">
              <img src={logo} alt="Daily Serving" />
            </div>
            <div className="daily-serving__text">
              <h2>
                Monday to Sunday, from <span>10:00 AM to 10:00 PM.</span>
                <a href="#contact">Contact us</a> for special events and
                occasions.
              </h2>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SmallBanner;
