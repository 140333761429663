import React from "react";
import AwesomeSlider from "react-awesome-slider";
import "react-awesome-slider/dist/styles.css";

class Menu extends React.Component {
  render() {
    return (
      <>
        <div
          id="menu"
          style={{
            background: `url(${require("../img/bg-paper.jpg")}) repeat`
          }}
        >
          <div className="container">
            <h1>Menu</h1>
            <div className="menu__halal">
              <img src={require("../img/svg/halal.svg")} alt="Halal" />
            </div>
          </div>
          <AwesomeSlider>
            <div
              data-src={require("../img/menu_new/01-Sup-Tomyam-Seafood-tabletp-768x432.jpg")}
            />
            <div
              data-src={require("../img/menu_new/02-Kepiting-Asam-Manis-tabletp-768x432.jpg")}
            />
            <div
              data-src={require("../img/menu_new/05-Gurami-3-Rasa-tabletp-768x432.jpg")}
            />
            <div
              data-src={require("../img/menu_new/06-Gurami-Steam-Lapis-tabletp-768x432.jpg")}
            />
            <div
              data-src={require("../img/menu_new/07-Ayam-Goreng-Sambal-Petir-tabletp-768x432.jpg")}
            />
            <div
              data-src={require("../img/menu_new/09-Gurami-Goreng-Terbang-tabletp-768x432.jpg")}
            />
            <div
              data-src={require("../img/menu_new/10-Buncis-Tumis-Teri-Sapi-Pedas-tabletp-768x432.jpg")}
            />
            <div
              data-src={require("../img/menu_new/11-Kangkung-Hot-Plate-Seafood-tabletp-768x432.jpg")}
            />
            <div
              data-src={require("../img/menu_new/12-Bandeng-Goreng-Crispy-tabletp-768x432.jpg")}
            />
          </AwesomeSlider>
        </div>
      </>
    );
  }
}

export default Menu;
