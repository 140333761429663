import React from "react";

class Contact extends React.Component {
  render() {
    return (
      <>
        <div
          id="contact"
          style={{
            background: `url(${require("../img/Map-desk-1366x522.jpg")}) repeat`
          }}
        >
          <div className="contact__overlay"></div>
          <div className="container" style={{ paddingLeft: 40 }}>
            <div className="contact__detail">
              <div>
                <h1>Contact</h1>
              </div>
              <ul>
                <li>
                  <span>
                    Jl. Kertanegara No. 1
                    <br/>
                    Malang, Indonesia
                    <br/>
                    <a
                      href="https://www.google.com/maps/place/Rumah+Makan+Kertanegara/@-7.977135,112.635751,17z/data=!3m1!4b1!4m2!3m1!1s0x2dd6283c719c2ffd:0x3d55f1188d084c02"
                      className="map-hover"
                    >
                      Google Maps »
                    </a>
                  </span>
                </li>
                <li>
                  <span>
                    <a href="tel:+62341366203">+62-341-366203 </a> <br/>
                    <a href="tel:+623419925200">+62-341-9925200 </a>
                  </span>
                </li>
                <li>
                  <span>
                    <a href="mailto: sales@kertanegaraguesthouse.com">
                      sales@kertanegaraguesthouse.com
                    </a>
                  </span>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default Contact;
