/* Using Menu components from https://codepen.io/naturalclar/pen/zEwvbg */
import React from "react";
import NavCanvas from "../components/NavCanvas";
import NavButton from "../components/NavButton";
import NavItem from "../components/NavItem";

export default class NavContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      menuOpen: false
    };
  }

  handleMenuClick() {
    this.setState({ menuOpen: !this.state.menuOpen });
  }

  handleLinkClick() {
    this.setState({ menuOpen: false });
  }

  render() {
    const menuObjects = [
      { name: "The Resto", link: "#the-resto" },
      { name: "Hours", link: "#hours" },
      { name: "Menu", link: "#menu" },
      { name: "Gallery", link: "#gallery" },
      { name: "Contact", link: "#contact" }
    ];

    const styles = {
      container: {
        position: "absolute",
        top: 0,
        left: 0,
        zIndex: "99",
        opacity: 0.9,
        display: "flex",
        alignItems: "center",
        background: "rgba(132, 7, 21, 1)",
        width: "100%",
        color: "white"
      },
      a: {
        color: "white"
      },
      logo: {
        margin: "0 auto"
      },
      body: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        width: "100vw",
        height: "100vh",
        filter: this.state.menuOpen ? "blur(2px)" : null,
        transition: "filter 0.5s ease"
      }
    };

    const menuItems = menuObjects.map((val, index) => {
      return (
        <NavItem key={index} delay={`${index * 0.1}s`}>
          <a
            href={val.link}
            style={styles.a}
            onClick={() => {
              this.handleLinkClick();
            }}
          >
            {val.name}
          </a>
        </NavItem>
      );
    });

    return (
      <div className="show-tablet show-mobile">
        <div style={styles.container}>
          <NavButton
            open={this.state.menuOpen}
            onClick={() => this.handleMenuClick()}
            color="white"
          />
        </div>
        <NavCanvas open={this.state.menuOpen}>{menuItems}</NavCanvas>
      </div>
    );
  }
}
