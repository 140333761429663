import React from "react";
import Navigation from "./components/Navigation";
import NavContainer from "./components/NavContainer";
import Content from "./components/Content";
import Menu from "./components/Menu";
import SmallBanner from "./components/SmallBanner";
import MozaicGallery from "./components/MozaicGallery";
import Contact from "./components/Contact";
import Footer from "./components/Footer";
import { photos } from "./components/photos";

function App() {
  return (
    <div className="App">
      <div className="wrapper">
        <Navigation />
        <NavContainer />
        <Content
          background={require("./img/BG-Web-1.jpg")}
          logo={require("./img/svg/Big-K-657x702.svg")}
        />
        <SmallBanner
          background={require("./img/BG-Web-2.png")}
          logo={require("./img/svg/Daily-Serving-428x144.svg")}
        />
        <Menu />
        <MozaicGallery photos={photos} />
        <Contact />
        <Footer
          year="2023"
          property="Rumah Makan Kertanegara"
          link="https://setiyaputra.me"
          creator="suryast"
        />
      </div>
    </div>
  );
}

export default App;
